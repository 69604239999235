import { Box, Typography } from "@mui/material";
import React from "react";

const FeatureCard = ({ featureCardOptions }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "144px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.pure.white,
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[400],
        borderRadius: 2,
        padding: 1,
      }}
    >
      <Box sx={{ marginBottom: 1 }}>{featureCardOptions.icon}</Box>
      <Box>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          {featureCardOptions.name}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "20px",
          height: "2px",
          backgroundColor: (theme) => theme.palette.primary[800],
          borderRadius: 4,
          marginTop: 1,
        }}
      />
      <Box sx={{ marginTop: 1, height: "48px" }}>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontWeight: 500,
            color: (theme) => theme.palette.terrain[600],
          }}
        >
          {featureCardOptions.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default FeatureCard;
