import { Box, Grid } from "@mui/material";
import React, { useState } from "react";

import EstablishmentCard from "./EstablishmentCard";
import SummaryCard from "./SummaryCard";
import { useParams } from "react-router-dom";
import UpgradeAccountModal from "../../../components/Modals/UpgradeAccountModal";
import UpgradeCard from "./UpgradeCard";
import { createStripeCheckoutSession } from "../../../clients/establishmentsClient";

const FreemiumDashboard = ({
  inventoryReportingOverview,
  staffMemberCount,
  establishment,
  memberships
}) => {
  const { establishmentid: establishmentId } = useParams();
  
    const [isLoadingSession, toggleLoadingSession] = useState(false);
  const [upgradeAccountModalOpen, setUpgradeAccountModalOpen] = useState(false);

  const handleModalToggle = () => {
    setUpgradeAccountModalOpen(!upgradeAccountModalOpen);
  };

  const handleCreateCheckout = async (interval) => {
    toggleLoadingSession(true);
    await createStripeCheckoutSession(establishmentId, interval);
    toggleLoadingSession(false);
  }

  const isEstablishmentOwner = memberships?.establishmentMemberships?.find(
    (x) => x.establishmentId === establishmentId
  )?.isOwner === true
    ? true
    : false;
  return (
    <Box sx={{ marginTop: 6 }}>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid
          item
          xs={12}
          sm={12}
          lg={4}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <EstablishmentCard
            establishment={establishment}
            handleModalToggle={handleModalToggle}
            isFreeAccount
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <SummaryCard
            header="Recipes"
            amount={inventoryReportingOverview?.recipeCount || 0}
            targetRoute={`/spec/${establishmentId}/recipes`}
            displayRestrictions
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <SummaryCard
            header="Menus"
            amount={inventoryReportingOverview?.menuCount || 0}
            targetRoute={`/spec/${establishmentId}/menus`}
            displayRestrictions
          />
        </Grid>

        <Grid item xs={6} sm={3} lg={2}>
          <SummaryCard
            header="Products"
            amount={inventoryReportingOverview?.productCount || 0}
            targetRoute={`/spec/${establishmentId}/productlibrary`}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <SummaryCard
            header="Staff Members"
            amount={staffMemberCount}
            targetRoute={`/homebase/${establishmentId}/staffmanagement`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 4, marginRight: 2 }}>
        <UpgradeCard handleModalToggle={handleModalToggle} />
      </Grid>

      <UpgradeAccountModal
        isOpen={upgradeAccountModalOpen}
        handleModalToggle={handleModalToggle}
        isLoadingSession={isLoadingSession}
        isOwner={isEstablishmentOwner}
        upgradeCallback={handleCreateCheckout}
      />
    </Box>
  );
};

export default FreemiumDashboard;
