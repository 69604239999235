import { Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import inventoryClient from "../../../clients/inventoryClient";
import utilFunctions from "../../../utilities/utilFunctions";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import useVendors from "../../../hooks/useVendors";
import inventoryClient from "../../../clients/inventoryClient";

const CartProductCard = ({
  cartItem,
  removeCartItem,
  locale,
  currency,
  currencySymbol,
  cartSearchString,
  countUpdateCallback,
}) => {
  const [orderAmount, setOrderAmount] = useState(0);
  const [isUpdatingItem, toggleUpdatingItem] = useState(false);
  const { data: vendorData, isLoading: isLoadingVendorData } = useVendors(
    cartItem.establishmentId
  );

  useEffect(() => {
    setOrderAmount(cartItem.orderAmount);
  }, [cartItem]);

  let sizeDisplay = utilFunctions.extractCartItemSizeName(cartItem);

  const handleOrderAmountUpdate = async (newOrderAmount) => {
    toggleUpdatingItem(true);
    await inventoryClient.patchCartItem(
      cartItem.establishmentId,
      cartItem.id,
      newOrderAmount
    );
    toggleUpdatingItem(false);
  };

  const handleRemoveCount = async () => {
    const currentWhole = Number(orderAmount);

    if (currentWhole === 0) {
      return;
    }

    const newOrderAmount = currentWhole - 1;
    await handleOrderAmountUpdate(newOrderAmount);
    setOrderAmount(`${newOrderAmount}`);
    countUpdateCallback(cartItem, newOrderAmount);
  };

  const handleAddCount = async () => {
    const currentWhole = Number(orderAmount);

    const newOrderAmount = currentWhole + 1;
    await handleOrderAmountUpdate(newOrderAmount);
    setOrderAmount(`${newOrderAmount}`);
    countUpdateCallback(cartItem, newOrderAmount);
  };

  if (isLoadingVendorData) {
    return null;
  }

  if (
    cartSearchString &&
    !cartItem?.productName
      ?.toLowerCase()
      .includes(cartSearchString.toLowerCase())
  ) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "76px !important",
          border: "1px solid",
          borderBottom: "none",
          borderColor: (theme) => theme.palette.terrain[400],
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          marginTop: 1,
          backgroundColor: (theme) => theme.palette.pure.white,
          display: "flex",
          flexDirection: "column",
          // position: "relative",
        }}
      >
        {/* VENDOR NAME */}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 1,
          }}
        >
          <Grid item>
            <Typography
              variant="smallButton"
              sx={{ color: (theme) => theme.palette.primary[800] }}
            >
              {vendorData?.vendors?.find((x) => x.id === cartItem.vendorId)
                ?.name || ""}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => removeCartItem(cartItem)}
              sx={{ width: "24px", height: "24px" }}
            >
              <CloseOutlinedIcon sx={{ fontSize: 18 }} />{" "}
            </IconButton>
          </Grid>
        </Grid>
        {/* PRODUCT NAME AND SIZE */}
        <Grid
          item
          sx={{ display: "flex", flexDirection: "row", paddingLeft: 2 }}
        >
          <Box>
            <Box
              component="img"
              style={{
                width: "40px",
                height: "40px",
                border: "1px solid",
                borderColor: "#c6c6c6",
                borderRadius: 4,
              }}
              src={
                cartItem?.imageUrl ||
                "https://cdn.shopify.com/s/files/1/0373/2593/0633/products/grande-absente-70cl_800x.png?v=1652958199"
              }
            />
          </Box>
          <Grid
            container
            direction="column"
            sx={{ paddingLeft: 2, paddingRight: 2 }}
          >
            <Grid item>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: 16, fontWeight: 700 }}
              >
                {cartItem.productName}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">{sizeDisplay}</Typography>{" "}
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", textAlign: "center", marginLeft: 0.5 }}
              >
                {" "}
                {cartItem.purchaseUnit
                  ? ` ${` ${cartItem.purchaseUnit.name} ${
                      cartItem.purchaseUnit.unitAmount === null
                        ? ""
                        : `(x${cartItem.purchaseUnit.unitAmount} units)`
                    }`}`
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifySelf: "flex-end",
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          backgroundColor: (theme) => theme.palette.terrain[100],
          width: "100%",
          height: "40px",
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item sx={{ paddingLeft: 2 }}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <IconButton
                  sx={{
                    borderRadius: "100%",
                    backgroundColor: (theme) => theme.palette.primary[800],
                    width: "24px",
                    height: "24px",
                  }}
                  disabled={isUpdatingItem}
                  onClick={handleRemoveCount}
                >
                  <RemoveOutlinedIcon
                    sx={{
                      color: (theme) => theme.palette.pure.white,
                      fontSize: 16,
                    }}
                  />{" "}
                </IconButton>
              </Grid>
              <Grid
                item
                sx={{ display: "flex", marginLeft: 2, marginRight: 2 }}
              >
                <Typography variant="subtitle2">{orderAmount}</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={{
                    borderRadius: "100%",
                    backgroundColor: (theme) => theme.palette.primary[800],
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary[400],
                    },
                  }}
                  disabled={isUpdatingItem}
                  onClick={handleAddCount}
                >
                  <AddOutlinedIcon
                    sx={{
                      color: (theme) => theme.palette.pure.white,
                      fontSize: 16,
                    }}
                  />{" "}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ paddingRight: 2 }}>
            <Typography variant="body2">
              {cartItem.purchaseUnit?.price?.amount !== null &&
              cartItem.purchaseUnit?.price?.amount !== undefined
                ? `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
                    cartItem.purchaseUnit?.price?.amount * orderAmount,
                    locale,
                    currency
                  )}`
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CartProductCard;
