import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import List from "@mui/material/List";
import { Grid, Link, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LiquorOutlinedIcon from "@mui/icons-material/LiquorOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import LocalBarOutlinedIcon from "@mui/icons-material/LocalBarOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import json2mq from "json2mq";
import EstablishmentPicker from "./components/EstablishmentPicker";
import WarningIcon from "@mui/icons-material/Warning";
import { useNavigate, Outlet, useParams } from "react-router-dom";
import Store from "../../Store/Store";
import {
  getEstablishment,
  getUserAdminEstablishments,
} from "../../clients/establishmentsClient";
import { IconButton, useMediaQuery } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalBar from "@mui/icons-material/LocalBar";
// import SavingsIcon from "@mui/icons-material/Savings";
import { getUserProfile } from "../../clients/authClient";
import EstablishmentDropdown from "./components/EstablishmentDropdown";
import SpecHeaderButton from "./components/SpecHeaderButton";
import DescriptionToolTip from "../DescriptionToolTip";
import useEstablishmentSettings from "../../hooks/useEstablishmentSettings";
import SpecSpinner from "../SpecSpinner";
import MenuNavIcon from "../CustomIcons/MenuNavIcon";
import HomeOptions from "./components/HomeOptions";
import MenuOptions from "./components/MenuOptions";
import RecipeOptions from "./components/RecipeOptions";
import InventoryOptions from "./components/InventoryOptions";
import LegacyMenuOptions from "./components/LegacyMenuOptions";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, useLargeDesktop }) => ({
    flexGrow: 1,
    paddingLeft: useLargeDesktop ? theme.spacing(1) : theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.down("md")]: { marginLeft: "0px !important" },
    marginLeft: "-240px !important",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      display: "flex",
      marginLeft: "24px !important",
    }),
    display: "flex",
    justifyContent: "center",
    marginTop: "66px",
    width: "100%",
    minWidth: "80%",
    height: `calc(100vh - 80px)`,
    alignItems: "flex-start",
  })
);

const menuIconButton = {
  display: "flex",
  marginLeft: { xs: 1, sm: 2 },
  width: "32px",
  height: "32px",
  borderRadius: 150,
  color: (theme) => theme.palette.terrain["800"],
  backgroundColor: "#000",
  border: "2px solid",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    background: (theme) => theme.palette.primary.main,
    borderRadius: 150,
  },
};

const Layout = () => {
  const [menuOptions, setMenuOptions] = useState([]);
  const [legacyMenuOptions, setLegacyMenuOptions] = useState([]);
  const [homeOptions, setHomeOptions] = useState([]);
  const [recipeOptions, setRecipeOptions] = useState([]);
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const useMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const useLargeDesktop = useMediaQuery(json2mq({ minWidth: 1680 }));
  // const location = useLocation();
  const store = useContext(Store);
  let navigate = useNavigate();
  let { establishmentid } = useParams();
  const { isLoading: isLoadingSettings } =
    useEstablishmentSettings(establishmentid);

  const handleDrawerToggle = () => {
    useMobile
      ? setMobileDrawerOpen(!mobileDrawerOpen)
      : store.toggleSideNavOpen();
  };

  const handleGetEstablishment = async () => {
    const estResponse = await getEstablishment(establishmentid);
    const adminEstablishments = await getUserAdminEstablishments();
    store.updateEstablishmentOptions(adminEstablishments);
    store.updateCurrentEstablishment(estResponse);
  };

  const handleChangeEstablishment = async (id) => {
    const estResponse = await getEstablishment(id);
    store.resetActiveRoutes();
    store.resetCachedEstablishmentData();
    store.updateCurrentEstablishment(estResponse);
  };

  const iconSize = { fontSize: 20 };

  const learnMore = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "44px",
        width: "100%",
        borderTop: "1px solid",
        borderColor: (theme) => theme.palette.terrain[400],
        paddingLeft: 2,
        position: "absolute",
        bottom: 4,
        left: 0,
      }}
    >
      <DescriptionToolTip
        header="Learn More"
        description="Click the Learn More link to visit our documentation page for more detailed descriptions of all features, terminology, how-to, etc."
      />
      <Link
        href="https://www.specapp.com/documentation"
        target="_blank"
        underline="none"
        rel="noopener"
      >
        <Typography
          variant="subtitle2"
          sx={{
            marginLeft: 2,
            color: (theme) => theme.palette.pure.black,
            "&:hover": {
              color: (theme) => theme.palette.pure.black,
            },
          }}
        >
          Learn More
        </Typography>
      </Link>
    </Box>
  );

  const loadUserProfile = async () => {
    const response = await getUserProfile();

    if (response.profile === null) {
      navigate("/create-profile");
    }

    store.updateUserProfile(response.profile);
    const userCountry = response.profile.country || "us";
    store.updateSelectedCountry(userCountry);
  };

  useEffect(() => {
    if (!store.currentEstablishment) {
      handleGetEstablishment(establishmentid);
    } else {
      setMenuOptions([
        {
          text: "Menus",
          route:
            store.activeRoutes?.menu ||
            `/spec/${store.currentEstablishment?.id}/menus`,
          icon: <MenuBookOutlinedIcon sx={iconSize} />,
        },
      ]);
      setHomeOptions([
        {
          text: "Dashboard",
          route: `/spec/${store.currentEstablishment?.id}/dashboard`,
          icon: <HomeOutlinedIcon sx={iconSize} />,
        },
      ]);
      setLegacyMenuOptions(
        store?.currentEstablishment?.isLegacyStructure
          ? [
              {
                text: "Legacy Menus",
                route: `/homebase/${store.currentEstablishment?.id}/menus`,
                icon: <WarningIcon sx={iconSize} />,
              },
            ]
          : []
      );
      setRecipeOptions([
        {
          text: "All Recipes",
          route:
            store.activeRoutes?.recipes ||
            `/spec/${store.currentEstablishment?.id}/recipes`,
          icon: <LocalBarOutlinedIcon sx={iconSize} />,
        },
        {
          text: "Spec Cocktails",
          route: `/homebase/${store.currentEstablishment?.id}/spec-classics`,
          icon: <LocalBar sx={iconSize} />,
        },
      ]);
      setInventoryOptions([
        {
          text: "Product Library",
          route:
            store.activeRoutes?.productLibrary ||
            `/spec/${store.currentEstablishment?.id}/productlibrary`,
          icon: <LiquorOutlinedIcon sx={iconSize} />,
        },
        {
          text: "Organization",
          route:
            store.activeRoutes?.organization ||
            `/spec/${store.currentEstablishment?.id}/organization`,
          icon: <AccountTreeOutlinedIcon sx={iconSize} />,
        },
        {
          text: "Vendors",
          route:
            store.activeRoutes?.vendors ||
            `/spec/${store.currentEstablishment?.id}/vendors`,
          icon: <LocalShippingOutlinedIcon sx={iconSize} />,
        },
        {
          text: "Ordering",
          route:
            store.activeRoutes?.ordering ||
            `/spec/${store.currentEstablishment?.id}/ordering`,
          icon: <ShoppingCartIcon sx={iconSize} />,
        },
        {
          text: "Invoices",
          route:
            store.activeRoutes?.invoices ||
            `/spec/${store.currentEstablishment?.id}/invoices`,
          icon: <ReceiptLongOutlinedIcon sx={iconSize} />,
        },
        {
          text: "Stock Count",
          route:
            store.activeRoutes?.stockCount ||
            `/spec/${store.currentEstablishment?.id}/stockcount`,
          icon: <InsertChartOutlinedIcon sx={iconSize} />,
        },
        // {
        //   text: "Reporting",
        //   route: `/spec/${store.currentEstablishment?.id}/reporting`,
        //   icon: <SavingsIcon sx={iconSize} />,
        // },
      ]);
    }
  }, [store.currentEstablishment, store?.activeRoutes]);

  useEffect(() => {
    if (!store.user) {
      loadUserProfile();
    }
  }, []);

  if (!store.currentEstablishment || !store.user || isLoadingSettings) {
    return <SpecSpinner open />;
  }

  const drawer = (
    <>
      <Grid
        container
        direction="column"
        sx={{
          display: "flex",
          overflow: "hidden",
          paddingLeft: 0,
          marginBottom: "52px",
        }}
      >
        <Grid
          item
          className="side-nav-bar"
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll !important",
            width: "100%",
          }}
        >
          <HomeOptions homeOptions={homeOptions} />
          <Divider sx={{ marginTop: 4 }} />
          <LegacyMenuOptions legacyMenuOptions={legacyMenuOptions} />
          <InventoryOptions inventoryOptions={inventoryOptions} />
          <RecipeOptions recipeOptions={recipeOptions} />
          <Divider sx={{ marginTop: 4 }} />
          <MenuOptions menuOptions={menuOptions} />
          <Divider sx={{ marginTop: 4 }} />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "2440px !important",
          margin: "auto",
          backgroundColor: (theme) => theme.palette.terrain[50],
        }}
      >
        <CssBaseline />
        {/* TOP NAVIGATION BAR */}
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            background: "#000 !important",
            boxShadow: "none",
            color: "#ffffff",
            paddingRight: { xs: 0, md: 4 },
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              margin: "auto",
              width: "100%",
            }}
          >
            {!useMobile ? (
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* MENU ICON AND SPEC DASHBOARD*/}
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  {/* MENU ICON */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      height: "66px",
                      marginLeft: 3,
                      marginRight: 2,
                    }}
                  >
                    <IconButton
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{
                        height: "32px",
                        width: "32px",
                        background: "none",
                        border: "2px solid",
                        borderRadius: "100%",
                        borderColor: (theme) =>
                          store.sideNavOpen
                            ? theme.palette.primary["main"]
                            : theme.palette.terrain[800],
                        color: (theme) =>
                          store.sideNavOpen
                            ? theme.palette.primary["main"]
                            : theme.palette.terrain[800],
                        "&:hover": {
                          background: "none",
                          border: "2px solid",
                          borderColor: (theme) => theme.palette.terrain[400],
                          color: (theme) => theme.palette.terrain[400],
                        },
                      }}
                    >
                      <MenuNavIcon
                        sx={{
                          fontSize: 18,
                        }}
                      />
                    </IconButton>
                  </Box>
                  {/* SPEC DASHBOARD */}
                  <Box
                    sx={{
                      backgroundColor: "#000000 !important",
                      height: "66px",
                      width: "220px",
                      zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <SpecHeaderButton />
                  </Box>
                </Grid>
                {/* ESTABLISHMENT PICKER AND NAV ICONS */}
                <Grid item xs={7}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: { xs: "space-between", md: "flex-end" },
                      marginBottom: 1,
                      marginRight: 1,
                    }}
                  >
                    <EstablishmentPicker
                      handleChangeEstablishment={handleChangeEstablishment}
                    />
                    <IconButton
                      color="inherit"
                      title="Staff Management"
                      onClick={() =>
                        navigate(
                          `/homebase/${store.currentEstablishment?.id}/staffmanagement`
                        )
                      }
                      sx={menuIconButton}
                    >
                      <PeopleAltOutlinedIcon
                        sx={{
                          fontSize: { xs: 14, sm: 18 },
                          color: "#fff",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      variant="outlined"
                      color="inherit"
                      title="Messages"
                      onClick={() =>
                        navigate(
                          `/homebase/${store.currentEstablishment?.id}/adminmessages`
                        )
                      }
                      sx={menuIconButton}
                    >
                      <EmailOutlinedIcon
                        sx={{
                          fontSize: { xs: 14, sm: 18 },
                          color: "#fff",
                        }}
                      />
                    </IconButton>

                    {/* SETTINGS BUTTON CAN BE TURNED BACK ON WHEN SETTINGS SCREEN IS READY */}
                    <IconButton
                      aria-label="Settings"
                      color="inherit"
                      title="Settings"
                      onClick={() =>
                        navigate(
                          `/spec/${store.currentEstablishment?.id}/settings`
                        )
                      }
                      sx={menuIconButton}
                    >
                      <SettingsOutlinedIcon
                        sx={{
                          fontSize: 18,
                          color: "#fff",
                        }}
                      />
                    </IconButton>
                    {/* Establishment Button goes here */}
                    <EstablishmentDropdown />
                  </Box>
                </Grid>

                {/* MOBILE VERSION */}
              </Grid>
            ) : (
              // {/* MOBILE VERSION */}
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                {/*SPEC DASHBOARD AND ESTBLISHMENT PICKER*/}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    height: "32px",
                    marginTop: 1,
                  }}
                >
                  {/* SPEC DASHBOARD */}
                  <Box
                    sx={{
                      backgroundColor: "#000000 !important",
                      marginLeft: -1,
                      width: "220px",
                      zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <SpecHeaderButton />
                  </Box>
                  {/* ESTABLISHMENT PICKER */}
                  <EstablishmentPicker
                    handleChangeEstablishment={handleChangeEstablishment}
                  />
                </Grid>
                {/* MENU ICON AND NAV ICONS */}
                <Grid
                  item
                  xs={12}
                  direction="row"
                  sx={{
                    display: "flex",
                    marginTop: 1,
                    marginRight: 1,
                    width: "100%",
                  }}
                >
                  {/* MENU ICON */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginLeft: 3,
                      marginRight: 2,
                      width: "40%",
                    }}
                  >
                    <IconButton
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{
                        height: "32px",
                        width: "32px",
                        background: "none",
                        border: "2px solid",
                        borderRadius: "100%",
                        borderColor: (theme) =>
                          store.sideNavOpen
                            ? theme.palette.primary["main"]
                            : theme.palette.terrain[800],
                        color: (theme) =>
                          store.sideNavOpen
                            ? theme.palette.primary["main"]
                            : theme.palette.terrain[800],
                        "&:hover": {
                          background: "none",
                          border: "2px solid",
                          borderColor: (theme) => theme.palette.terrain[400],
                          color: (theme) => theme.palette.terrain[400],
                        },
                      }}
                    >
                      <MenuNavIcon
                        sx={{
                          fontSize: 18,
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "50%",
                      marginRight: 1,
                    }}
                  >
                    <IconButton
                      color="inherit"
                      title="Staff Management"
                      onClick={() =>
                        navigate(
                          `/homebase/${store.currentEstablishment?.id}/staffmanagement`
                        )
                      }
                      sx={menuIconButton}
                    >
                      <PeopleAltOutlinedIcon
                        sx={{
                          fontSize: { xs: 14, sm: 18 },
                          color: "#fff",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      variant="outlined"
                      color="inherit"
                      title="Messages"
                      onClick={() =>
                        navigate(
                          `/homebase/${store.currentEstablishment?.id}/adminmessages`
                        )
                      }
                      sx={menuIconButton}
                    >
                      <EmailOutlinedIcon
                        sx={{
                          fontSize: { xs: 14, sm: 18 },
                          color: "#fff",
                        }}
                      />
                    </IconButton>

                    {/* SETTINGS BUTTON CAN BE TURNED BACK ON WHEN SETTINGS SCREEN IS READY */}
                    <IconButton
                      aria-label="Settings"
                      color="inherit"
                      title="Settings"
                      onClick={() =>
                        navigate(
                          `/spec/${store.currentEstablishment?.id}/settings`
                        )
                      }
                      sx={menuIconButton}
                    >
                      <SettingsOutlinedIcon
                        sx={{
                          fontSize: 18,
                          color: "#fff",
                        }}
                      />
                    </IconButton>
                    {/* Establishment Button goes here */}
                    <EstablishmentDropdown />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { md: drawerWidth },
          }}
          aria-label="mailbox folders"
        >
          {useMobile ? (
            <Drawer
              variant="temporary"
              open={useMobile ? mobileDrawerOpen : store.sideNavOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: false,
              }}
              PaperProps={{
                style: {
                  height: `calc(98% - 66px)`,
                  position: "fixed",
                  top: "67px",
                  marginLeft: 10,
                },
              }}
              sx={{
                display: { xs: "block", sm: "block", md: "none" },

                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  backgroundColor: (theme) => theme.palette.terrain[100],
                  color: (theme) => theme.palette.terrain[900],
                  borderRadius: 2,
                  margin: 2,
                  padding: 0.5,
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                },
              }}
            >
              {drawer}
              {learnMore}
            </Drawer>
          ) : (
            <Drawer
              PaperProps={{
                style: {
                  height: `calc(98% - 66px)`,
                  position: "fixed",
                  top: "67px",
                  marginLeft: 10,
                },
              }}
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  backgroundColor: (theme) => theme.palette.terrain[100],
                  color: (theme) => theme.palette.terrain[900],
                  borderRadius: 2,
                  margin: 2,
                  padding: 0.5,
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                },
              }}
              variant="persistent"
              anchor="left"
              open={store.sideNavOpen}
            >
              {drawer}
              {learnMore}
            </Drawer>
          )}
        </Box>
        <Main
          useLargeDesktop={useLargeDesktop}
          sx={{ marginLeft: useMobile && "0px" }}
          open={store.sideNavOpen}
        >
          <Toolbar />

          <Outlet />
        </Main>
      </Box>
    </>
  );
};

export default Layout;
