import React from "react";
import { Button, Typography } from "@mui/material";

const AddMissingDetailsButton = ({ text, onClick }) => {
  return (
    <Button
      variant="contained"
      size="medium"
      fullWidth
      onClick={onClick}
      sx={{
        color: (theme) => theme.palette.primary[800],
        backgroundColor: (theme) => theme.palette.terrain[100],
        height: "36px",
        "&:hover": {
          border: "none",
          backgroundColor: (theme) => theme.palette.terrain[300],
        },
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textTransform: "none",
          fontSize: "12px",
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

export default AddMissingDetailsButton;
