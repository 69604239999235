import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography, Grid, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Store from "../../../Store/Store";

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  borderTop: "1px solid ",
  borderColor: theme.palette.terrain[400],
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  // marginLeft: 10,
  marginRight: 0,
  width: "97%",
  maxWidth: "2440px",
  height: "80px",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(98% - 250px)`,
    maxWidth: "2200px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  display: "flex",
  alignItems: "center",
}));

const OrderReviewFooter = ({ submit, productCount, estimatedCost }) => {
  const [loading, toggleLoading] = useState(false);
  const { sideNavOpen } = useContext(Store);

  useEffect(() => {
    toggleLoading(false);
  });

  return (
    <FooterGridWrapper open={sideNavOpen}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "2440px",
        }}
      >
        <Grid
          item
          sx={{
            paddingLeft: 8,
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              marginRight: 1,
            }}
          >
            Products:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.pure.black, marginRight: 1 }}
          >
            {productCount}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              marginRight: 1,
            }}
          >
            {"|"}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              marginRight: 1,
            }}
          >
            Estimated Total Cost:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.pure.black, marginRight: 1 }}
          >
            {estimatedCost}
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ padding: 2 }}>
            <Button
              type="button"
              variant="contained"
              sx={{ width: { xs: "140px", md: "180px" }, height: "42px" }}
              onClick={submit}
            >
              <Typography variant="largeButton">
                {loading ? <CircularProgress color="inherit" /> : "Confirm"}
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FooterGridWrapper>
  );
};

export default OrderReviewFooter;
