import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useMutation, useQueryClient } from "react-query";
import Store from "../../../Store/Store";
import inventoryClient from "../../../clients/inventoryClient";
import useOrderCart from "../../../hooks/useOrderCart";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import utilFunctions from "../../../utilities/utilFunctions";
import AddMissingDetailsButton from "./AddMissingDetailsButton";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import { useParams } from "react-router-dom";
import useEstablishmentSubscription from "../../../hooks/useEstablishmentSubscription";

const selectPlaceholder = { id: -1, name: "" };
const NewOrderProductCard = ({
  product,
  setMissingDetailsData,
  vendorFilter,
}) => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { currency, locale, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );
  const [currentVendor, setCurrentVendor] = useState(selectPlaceholder);
  const [isInitialRender, toggleIsInitialRender] = useState(true);
  const [currentFormat, setCurrentFormat] = useState({});
  const [orderQuantity, setOrderQuantity] = useState(1);
  const [filteredFormatOptions, setFilteredFormatOptions] = useState([]);
  const [currentPurchaseUnit, setCurrentPurchaseUnit] =
    useState(selectPlaceholder);
  const queryClient = useQueryClient();
  const { establishmentid } = useParams();
  const { data: subscriptionState } = useEstablishmentSubscription(establishmentid);
  const cartData = useOrderCart(store.currentEstablishment?.id, !!subscriptionState?.isActive);
  const productAdded = Boolean(
    cartData?.data?.cart?.items.find(
      (x) => x.purchaseUnitId === currentPurchaseUnit.id
    )
  );

  const handleMissingDetailsClick = () => {
    setMissingDetailsData({
      vendor: currentVendor,
      purchaseUnit: currentPurchaseUnit,
      product,
      currentFormat,
      orderQuantity,
    });
  };

  useEffect(() => {
    let defaultFormat = product?.productFormats?.find(
      (x) => x.isDefault === true
    );

    if (!defaultFormat) {
      defaultFormat = product?.productFormats[0];
    }
    setCurrentFormat(defaultFormat);
    let defaultPurchaseUnit = defaultFormat.purchaseDetails?.find(
      (x) => x.isDefault
    );

    if (defaultPurchaseUnit) {
      setCurrentVendor(defaultPurchaseUnit.vendor || selectPlaceholder);
      setCurrentPurchaseUnit(defaultPurchaseUnit);
    } else {
      defaultPurchaseUnit = defaultFormat.purchaseDetails[0];
      setCurrentPurchaseUnit(defaultPurchaseUnit || selectPlaceholder);
      setCurrentVendor(defaultPurchaseUnit?.vendor || selectPlaceholder);
    }
  }, [product]);

  useEffect(() => {
    if (vendorFilter) {
      // we find any formats the have a PU for the vendor that was selected
      // then we create a filtered set of options
      const matchingFormats = [];
      product.productFormats.forEach((x) => {
        let containsMatch = false;
        const validPUs = [];

        x.purchaseDetails.forEach((pu) => {
          if (vendorFilter?.id === pu.vendorId) {
            containsMatch = true;
            validPUs.push(pu);
          }
        });

        if (containsMatch) {
          const filteredFormat = Object.assign({}, x, {
            purchaseDetails: validPUs,
          });
          console.log(filteredFormat);
          matchingFormats.push(filteredFormat);
        }
      });
      setFilteredFormatOptions(matchingFormats);

      // we also need to update the current format and PU to be valid options based on the new filtered list
      if (matchingFormats.length > 0) {
        const defaultInList =
          matchingFormats.find((x) => x.isDefault === true) ||
          matchingFormats[0];
        setCurrentFormat(defaultInList);
        setCurrentPurchaseUnit(
          defaultInList.purchaseDetails.find((x) => x.isDefault === true) ||
            defaultInList.purchaseDetails[0]
        );
      }
    } else if (isInitialRender) {
      toggleIsInitialRender(false);
    } else {
      const unfilteredCurrentFormat = product.productFormats.find(
        (x) => x.id === currentFormat.id
      );
      setCurrentFormat(unfilteredCurrentFormat);
    }
  }, [vendorFilter]);

  const addCartItemsMutation = useMutation(inventoryClient.addCartItems, {
    onSuccess: (result) => {
      queryClient.setQueryData(
        ["ordercart", { establishmentId: store.currentEstablishment?.id }],
        (prev) => {
          const newCartData = prev.cart.items.map((x) => {
            return Object.assign({}, x);
          });

          return { cart: { items: [...newCartData, ...result.newCartItems] } };
        }
      );
    },
  });

  const handleAddItem = async () => {
    await addCartItemsMutation.mutateAsync({
      establishmentId: store.currentEstablishment?.id,
      items: [
        {
          purchaseUnitId: currentPurchaseUnit?.id,
          productSizeId: currentFormat.id,
          productId: product.id,
          vendorId: currentVendor.id,
          orderAmount: orderQuantity,
        },
      ],
    });
  };

  const vendorOptions = {};
  currentFormat.purchaseDetails?.forEach((d) => {
    if (d.vendorId !== null) {
      vendorOptions[d.vendorId] = d.vendor;
    }
  });

  const renderPurchaseUnitOptions = () => {
    if (currentFormat.purchaseDetails?.length === 0) {
      return (
        <AddMissingDetailsButton
          text={"+ Add Unit Type"}
          onClick={handleMissingDetailsClick}
        />
      );
    }

    if (!currentPurchaseUnit?.name || !currentPurchaseUnit?.unitAmount) {
      return (
        <AddMissingDetailsButton
          text={"+ Add Unit Type"}
          onClick={handleMissingDetailsClick}
        />
      );
    }

    return (
      <TextField
        select
        size="small"
        variant="outlined"
        label="Purchase Unit"
        fullWidth
        value={currentPurchaseUnit?.id || ""}
        onChange={handlePurchaseUnitChange}
        disableUnderline
        SelectProps={{
          MenuProps: {
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: (theme) => theme.palette.primary[800],
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: (theme) => theme.palette.secondary[600],
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: (theme) => theme.palette.secondary[800],
              },
            },
          },
        }}
      >
        {currentFormat?.purchaseDetails?.map((o) => (
          <MenuItem key={o.id} value={o.id}>{`${o.name} ${
            o.unitAmount === null ? "" : `(x${o.unitAmount})`
          }`}</MenuItem>
        ))}
      </TextField>
    );
  };

  const renderProductFormatOptions = () => {
    const formatsToRender = vendorFilter
      ? filteredFormatOptions
      : product.productFormats;
    return (
      <TextField
        select
        size="small"
        variant="outlined"
        label="Unit Size"
        InputLabelProps={{ shrink: true }}
        fullWidth
        value={currentFormat?.id || ""}
        onChange={handleProductFormatChange}
        disableUnderline
        SelectProps={{
          MenuProps: {
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: (theme) => theme.palette.primary[800],
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: (theme) => theme.palette.secondary[600],
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: (theme) => theme.palette.secondary[800],
              },
            },
          },
        }}
      >
        {formatsToRender.map((x) => (
          <MenuItem key={x.id} value={x.id}>
            {utilFunctions.extractFormatSizingName(x)}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  // const handleVendorChange = (e) => {
  //   setCurrentVendor(vendorOptions[e.target.value]);
  //   const defaultForVendor = currentFormat.purchaseDetails?.find(
  //     (d) => d.vendorId === e.target.value
  //   );
  //   setCurrentPurchaseUnit(defaultForVendor || selectPlaceholder);
  // };

  const handlePurchaseUnitChange = (e) => {
    setCurrentPurchaseUnit(
      currentFormat.purchaseDetails.find((x) => x.id === e.target.value)
    );
  };

  const handleProductFormatChange = (e) => {
    const formatsToUse = vendorFilter
      ? filteredFormatOptions
      : product.productFormats;
    const newCurrentFormat = formatsToUse?.find((x) => x.id === e.target.value);

    let defaultPurchaseUnit = newCurrentFormat.purchaseDetails?.find(
      (x) => x.isDefault
    );
    console.log(newCurrentFormat);
    if (defaultPurchaseUnit) {
      setCurrentVendor(defaultPurchaseUnit.vendor || selectPlaceholder);
      setCurrentPurchaseUnit(defaultPurchaseUnit);
    } else {
      defaultPurchaseUnit = newCurrentFormat.purchaseDetails[0];
      setCurrentPurchaseUnit(defaultPurchaseUnit || selectPlaceholder);
      setCurrentVendor(defaultPurchaseUnit?.vendor || selectPlaceholder);
    }

    setCurrentFormat(newCurrentFormat);
  };

  const handleOrderQuantityChange = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setOrderQuantity(e.target.value);
    }
  };

  if (vendorFilter && filteredFormatOptions?.length === 0) {
    return null;
  }

  return (
    <Grid item xs={6} md={4} lg={3} sx={{ marginTop: 2 }}>
      <Box
        sx={{
          height: "43px",
          width: "100%",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: 2,
          marginBottom: 0,
          border: "1px solid",
          borderBottom: "none",
          backgroundColor: (theme) =>
            productAdded
              ? theme.palette.success[800]
              : theme.palette.pure.white,
          borderColor: (theme) =>
            productAdded
              ? theme.palette.success[800]
              : theme.palette.terrain[400],
        }}
      >
        <Typography
          variant="subtitle1"
          noWrap
          sx={{
            fontWeight: 700,
            color: (theme) =>
              productAdded
                ? theme.palette.pure.white
                : theme.palette.pure.black,
          }}
        >
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              minWidth: "100%",
              maxWidth: "100%",
              textTransform: "none",
            }}
          >
            {product.name}
          </div>
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          borderLeft: "1px solid",
          borderRight: "1px solid",
          borderBottom: "none",
          borderTop: "none",
          borderColor: (theme) =>
            productAdded
              ? theme.palette.success[800]
              : theme.palette.terrain[400],
          backgroundColor: (theme) => theme.palette.pure.white,
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 3,
          }}
        >
          {/* UNIT SIZE */}
          <Grid item xs={5} sx={{ marginLeft: 2 }}>
            {renderProductFormatOptions()}
          </Grid>
          {/* Purchase Unit */}
          <Grid item xs={6} sx={{ marginRight: 2 }}>
            {renderPurchaseUnitOptions()}
          </Grid>
        </Grid>
        {/* VENDOR */}
        <Grid item sx={{ width: "100%", padding: 2, marginTop: 1 }}>
          {currentPurchaseUnit?.vendor === null ||
          currentPurchaseUnit?.vendor === undefined ? (
            <Box sx={{ height: "36px" }}>
              <AddMissingDetailsButton
                text="+ Add Vendor"
                onClick={handleMissingDetailsClick}
              />
            </Box>
          ) : (
            <Box sx={{ height: "36px", display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                size="small"
                fullWidth
                disableUnderline
                label="Vendor"
              >
                Vendor:
              </Typography>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  marginLeft: 2,
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "100%",
                    maxWidth: "100%",
                    textTransform: "none",
                  }}
                >
                  {currentPurchaseUnit?.vendor?.name || ""}
                </div>
              </Typography>
            </Box>
          )}
        </Grid>
        {/* IMAGE */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginLeft: 2,
            marginRight: 2,
            maxWidth: "95.5%",
            width: "95.5%",
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[400],
            borderRadius: 1,
            padding: 1,
            aspectRatio: "1 / 1",
          }}
        >
          {currentFormat?.imageUrl ? (
            <Box
              component="img"
              sx={{
                width: "100%",
                objectFit: "scale-down",
                aspectRatio: 1 / 1,
              }}
              src={currentFormat?.imageUrl}
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                objectFit: "scale-down",
                aspectRatio: 1 / 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <QuestionMarkOutlinedIcon
                sx={{
                  fontSize: 72,
                  color: (theme) => theme.palette.terrain[300],
                }}
              />
              <Typography
                variant="overline"
                sx={{
                  fontWeight: 700,
                  color: (theme) => theme.palette.terrain[300],
                  marginTop: 12,
                  textAlign: "center",
                }}
              >
                IMAGE MISSING FROM PRODUCT FORMAT
              </Typography>
            </Box>
          )}
        </Box>

        {/* QUANTITY AND PRICE */}
        <Grid
          container
          direction="row"
          sx={{
            marginTop: 3,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item sx={{ width: "25%", marginLeft: 2 }}>
            <TextField
              size="small"
              label="Quantity"
              placeholder="# of Purchase Units"
              value={orderQuantity}
              InputLabelProps={{ shrink: true, required: true }}
              onChange={handleOrderQuantityChange}
            />
          </Grid>
          <Grid item sx={{ width: "68%", marginRight: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                backgroundColor: (theme) => theme.palette.terrain[100],
                borderRadius: 1,
                height: "36px",
                marginLeft: 1,
              }}
            >
              {currentPurchaseUnit?.price === null ||
              currentPurchaseUnit?.price === undefined ? (
                <AddMissingDetailsButton
                  text="+ Add Price"
                  onClick={handleMissingDetailsClick}
                />
              ) : (
                <Typography variant="h4" sx={{ marginRight: 2 }}>
                  {currentPurchaseUnit?.price?.amount !== null &&
                  currentPurchaseUnit?.price?.amount !== undefined
                    ? `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
                        currentPurchaseUnit?.price?.amount,
                        locale,
                        currency
                      )}`
                    : "N/A"}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        {/* BUTTON */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 2,
          }}
        >
          {addCartItemsMutation.isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="text"
              disabled={
                addCartItemsMutation.isLoading ||
                currentVendor.id === -1 ||
                currentPurchaseUnit.id === -1
              }
              sx={{
                width: "100%",
                height: "40px",
                backgroundColor: (theme) =>
                  productAdded
                    ? theme.palette.success[50]
                    : theme.palette.terrain[50],
                marginLeft: 2,
                marginRight: 2,
                marginBottom: 2,
              }}
              onClick={handleAddItem}
            >
              {productAdded ? (
                <CheckOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: (theme) => theme.palette.success[800],
                  }}
                />
              ) : (
                <AddOutlinedIcon
                  sx={{
                    fontSize: 20,
                  }}
                />
              )}
              <Typography
                variant="largeButton"
                sx={{
                  marginLeft: 1,
                  color: (theme) => productAdded && theme.palette.success[800],
                }}
              >
                {productAdded ? "Added to Cart" : "Add to Cart"}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      {/* PAR LEVELS */}
      <Box
        sx={{
          width: "100%",
          height: "40px",
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            item
            xs={4}
            sx={{
              borderTop: "1px solid #c6c6c6 !important",
              borderRight: "1px solid #c6c6c6 !important",
              border: "1px solid",
              borderColor: (theme) =>
                productAdded
                  ? theme.palette.success[800]
                  : theme.palette.terrain[400],
              backgroundColor: (theme) => theme.palette.error[50],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderBottomLeftRadius: 8,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  color: (theme) => theme.palette.pure.black,
                }}
              >
                Low Par:
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">{currentFormat.par?.low}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              borderTop: "1px solid #c6c6c6 !important",
              borderBottom: "1px solid",
              borderColor: (theme) =>
                productAdded
                  ? theme.palette.success[800]
                  : theme.palette.terrain[400],
              backgroundColor: (theme) => theme.palette.info[50],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  color: (theme) => theme.palette.pure.black,
                }}
              >
                High Par:
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">{currentFormat.par?.high}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              borderTop: "1px solid #c6c6c6 !important",
              borderLeft: "1px solid #c6c6c6 !important",
              border: "1px solid",
              borderColor: (theme) =>
                productAdded
                  ? theme.palette.success[800]
                  : theme.palette.terrain[400],
              backgroundColor: (theme) => theme.palette.warning[50],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderBottomRightRadius: 8,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  color: (theme) => theme.palette.pure.black,
                }}
              >
                On Hand:
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">
                {currentFormat.inventoryState
                  ? currentFormat.inventoryState.onHand
                  : "-"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default NewOrderProductCard;
