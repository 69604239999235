import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Grid,
  CircularProgress,
  Switch,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const centerItems = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

const MenuManagementModalV2 = ({
  onSave,
  modalOpen,
  toggleModalOpen,
  isLoading,
  existingMenu,
}) => {
  const [menuTitle, setMenuTitle] = useState("");
  const [menuActive, toggleMenuActive] = useState(true);
  const [menuDescription, setMenuDescription] = useState("");
  const [showTitleError, toggleShowTitleError] = useState(false);
  const [showTypeError, toggleShowTypeError] = useState(false);
  const [shareWithStaff, toggleShareWithStaff] = useState(true);
  const [markUp, setMarkUp] = useState(5);

  useEffect(() => {
    if (existingMenu) {
      setMenuTitle(existingMenu.name);
      toggleMenuActive(existingMenu.isActive);
      setMarkUp(existingMenu.defaultMarkUp);
      setMenuDescription(existingMenu.description);
      toggleShareWithStaff(existingMenu.isSharedWithStaff)
    }
  }, [existingMenu]);

  const closeModal = () => {
    toggleShowTitleError(false);
    toggleShowTypeError(false);
    toggleModalOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationError = false;

    if (!menuTitle) {
      toggleShowTitleError(true);
      validationError = true;
    }

    if (validationError) {
      return;
    }

    await onSave({
      name: menuTitle,
      active: menuActive,
      markUp: markUp,
      description: menuDescription,
      isSharedWithStaff: shareWithStaff,
    });

    closeModal();
  };

  const validateMarkUp = () => {
    if (typeof markUp !== "number" && isNaN(markUp)) {
      setMarkUp(1);
    }
  };

  return (
    <Modal open={modalOpen} onClose={closeModal} showTypeError={showTypeError}>
      <Box
        onSubmit={handleSubmit}
        component="form"
        sx={[modalBoxStyle, { width: "400px !important" }]}
      >
        <Box>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 6,
              }}
            >
              <Grid item>
                <Typography variant="h4">
                  {existingMenu ? "Edit Menu" : "Create New Menu"}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ marginBottom: 6, width: "100%" }}>
            <TextField
              size="small"
              label="Menu Title&#42;"
              id="title"
              required
              value={menuTitle}
              fullWidth
              onChange={(e) => setMenuTitle(e.target.value)}
              helperText={showTitleError ? "This field is mandatory" : ""}
              error={showTitleError}
            />
          </Grid>
          <Grid item sx={{ marginBottom: 6, width: "100%" }}>
            <TextField
              size="small"
              label="Description"
              id="description"
              placeholder="Enter Description"
              fullWidth
              value={menuDescription}
              multiline
              minRows={3}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setMenuDescription(e.target.value)}
              error={showTitleError}
            />
          </Grid>

          <Grid item sx={{ marginBottom: 6, width: "100%" }}>
            <TextField
              size="small"
              label="Default Mark Up&#42;"
              id="merkUp"
              value={markUp}
              fullWidth
              onChange={(e) => setMarkUp(e.target.value)}
              onBlur={validateMarkUp}
            />
          </Grid>

          <Grid item>
            <Box
              sx={{
                // width: "360px",
                height: "40px",
                backgroundColor: (theme) => theme.palette.secondary[50],
                borderRadius: 2,
              }}
            >
              <Grid container direction="row">
                <Grid item>
                  <Switch
                    checked={menuActive}
                    onClick={() => toggleMenuActive(!menuActive)}
                  />
                </Grid>
                <Grid item sx={{ alignItems: "center", display: "flex" }}>
                  {" "}
                  <Typography variant="subtitle2">
                    {menuActive
                      ? "Menu Status: Active"
                      : "Menu Status: Inactive"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sx={{ marginTop: 2 }}>
            <Box
              sx={{
                // width: "360px",
                height: "40px",
                backgroundColor: (theme) => theme.palette.secondary[50],
                borderRadius: 2,
              }}
            >
              <Grid container direction="row">
                <Grid item>
                  <Switch
                  checked={shareWithStaff}
                  onClick={() => toggleShareWithStaff(!shareWithStaff)}
                  />
                </Grid>
                <Grid item sx={{ alignItems: "center", display: "flex" }}>
                  {" "}
                  <Typography variant="subtitle2">
                    {shareWithStaff ? "Show to Staff" : "Hide from Staff"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sx={[centerItems, { marginTop: 6 }]}>
            <Button
              variant="contained"
              type="submit"
              sx={{ marginLeft: "1rem", borderRadius: 2, width: "240px" }}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Typography variant="smallButton">Confirm</Typography>
              )}
            </Button>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

MenuManagementModalV2.propTypes = {};

export default MenuManagementModalV2;
