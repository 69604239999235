import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useExistingOrders(establishmentId, page, enabled) {
    const queryReturnValues = useQuery(
        ["existing-orders", { establishmentId, page }],
        inventoryClient.getExistingOrders,
        {enabled, refetchOnWindowFocus: false}
    );

   return queryReturnValues;
}

export default useExistingOrders;