import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Alert,
  Box,
  IconButton,
  Grid,
  Snackbar,
  Typography,
  List,
  ListItem,
  Popover,
  TextField,
  Badge,
  Pagination,
  CircularProgress,
} from "@mui/material";
import LiveEditErrorModal from "../../components/Modals/LiveEditErrorModal";
import { useNavigate, useParams } from "react-router-dom";
import SpecBackButton from "../../components/SpecBackButton";
// import NewOrderFooter from "./components/NewOrderFooter";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CartProductCard from "./components/CartProductCard";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import NewOrderTable from "./components/NewOrderTable";
import Store, { activeRouteKeys } from "../../Store/Store";
import SpecSpinner from "../../components/SpecSpinner";
import useOrderCart from "../../hooks/useOrderCart";
import inventoryClient from "../../clients/inventoryClient";
import { useMutation, useQueryClient } from "react-query";
import useVendorCurrency from "../../hooks/useVendorCurrency";
import ProductDetailsMissingModal from "../../components/Modals/ProductDetailsMissingModal";
import useVendors from "../../hooks/useVendors";
// import VendorFilterChip from "./components/VendorFilterChip";
import useEstablishmentUoMs from "../../hooks/useEstablishmentUoMs";
import { debounce } from "lodash";
import useEstablishmentSettings from "../../hooks/useEstablishmentSettings";
import useProductLibraryPaginatedSearch from "../../hooks/usePaginatedProductLibrarySearch";
import inventoryObjects from "../../clients/inventoryObjects";
import useProductLibraryPaginated from "../../hooks/useProductLibraryPaginated";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";

const NewOrder = () => {
  const [errorModalOpen, toggleErrorModalOpen] = useState(false);
  const [productDetailsMissingOpen, toggleProductDetailsMissingOpen] =
    useState(false);
  const [missingDetailsData, setMissingDetailsData] = useState(null);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  // const [vendorFilter, setVendorFilter] = useState(undefined);
  const [cartSearchString, setCartSearchString] = useState("");
  const [page, setPage] = useState(1);
  // const [filters, setFilters] = useState({});
  // const [sortAscending, toggleSortAscending] = useState(true);
  // const [propertyForSort, setPropertyForSort] = useState(inventoryObjects.productSortProperties.name);
  const [searchContent, setSearchContent] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const store = useContext(Store);
  const topOfPageRef = useRef();
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
    );
  const { locale, currency, currencySymbol } = useVendorCurrency(
      settings?.inventorySettings?.currency
      );
  const [{ isLoading: isLoadingVendors, data: vendorData }] = useVendors(
        store.currentEstablishment?.id
        );
  const { establishmentid } = useParams();
  const { data: subscriptionState, isLoading: isLoadingSubscriptionState } = useEstablishmentSubscription(establishmentid);

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.ordering,
      `spec/${establishmentid}/ordering/new`
    );
  }, []);

  const navigate = useNavigate();

  const {
    data: paginatedProductData,
    isLoading: isLoadingPaginatedProducts,
    // isFetching: isFetchingPaginated,
  } = useProductLibraryPaginated(
    establishmentid,
    page,
    {},
    true,
    inventoryObjects.productSortProperties.name
  );
  const { data: paginatedSearchData, isLoading: isLoadingPaginatedSearchData } =
    useProductLibraryPaginatedSearch(
      establishmentid,
      searchContent,
      page,
      {},
      true,
      inventoryObjects.productSortProperties.name
    );
  const { isLoading: isLoadingCart, data: cartData } = useOrderCart(
    store.currentEstablishment?.id,
    !!subscriptionState?.isActive
  );
  const { isLoading: isLoadingUoMs, data: uomData } = useEstablishmentUoMs(
    store.currentEstablishment?.id
  );
  const queryClient = useQueryClient();
  const removeCartItemMutation = useMutation(inventoryClient.removeCartItem, {
    onSuccess: (result, variables) => {
      queryClient.setQueryData(
        ["ordercart", { establishmentId: store.currentEstablishment?.id }],
        (prev) => {
          const newCartData = [];

          prev.cart.items?.forEach((x) => {
            if (x.id !== variables.cartItemId) {
              newCartData.push(x);
            }
          });

          return { cart: { items: newCartData } };
        }
      );
    },
  });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSetMissingDetailsData = (data) => {
    setMissingDetailsData(data);
    toggleProductDetailsMissingOpen(true);
  };

  const handleCloseMissingDetailsModal = () => {
    toggleProductDetailsMissingOpen(false);
    setMissingDetailsData(null);
  };

  const handleUpdateCartSearchString = (e) => {
    setCartSearchString(e.target.value);
  };

  const debouncedhandleUpdateCartSearchString = useMemo(() =>
    debounce(handleUpdateCartSearchString, 300)
  );

  if (
    isLoadingPaginatedProducts ||
    isLoadingCart ||
    isLoadingVendors ||
    isLoadingUoMs ||
    isLoadingSubscriptionState
  ) {
    return <SpecSpinner open={true} text={"Prepping for Ordering..."} />;
  }

  const handleRemoveCartItem = async (cartItem) => {
    await removeCartItemMutation.mutateAsync({
      establishmentId: cartItem.establishmentId,
      cartItemId: cartItem.id,
    });
  };

  // const handleVendorChipClick = (vendor) => {
  //   if (vendorFilter && vendorFilter.id === vendor.id) {
  //     setVendorFilter(undefined);
  //   } else {
  //     setVendorFilter(vendor);
  //   }
  // };

  const handleSearch = (e) => {
    if (!e.target.value) {
      queryClient.invalidateQueries({
        queryKey: ["inventory-paginated"],
      });
      queryClient.refetchQueries({
        queryKey: ["inventory-paginated"],
        type: "all",
      });
    }
    setSearchContent(e.target.value);
  };

  const debouncedSearchHandler = debounce(handleSearch, 300);

  const handleSearchChange = (e) => {
    debouncedSearchHandler.cancel();
    setPage(1);
    debouncedSearchHandler(e);
  };

  const handlePageSet = (_, page) => {
    setPage(page);
    topOfPageRef?.current?.scrollIntoView();
  };

  const handleCountUpdateCallback = (cartItem, newAmount) => {
    const state = queryClient.getQueryState({
      queryKey: ["ordercart", { establishmentId: store.currentEstablishment?.id }],
    });
    const newState = state.data;

    newState.cart.items = newState?.cart?.items?.map((x) => {
      if (x.id === cartItem.id) {
        return Object.assign({}, x, { orderAmount: newAmount });
      }

      return x;
    });

    queryClient.setQueryData(
      ["ordercart", { establishmentId: store.currentEstablishment?.id }],
      newState
    );
  }

  return (
    <>
      <Box
        component="div"
        sx={{ display: "flex", width: "100%", padding: 4 }}
      >
          <Grid container direction="column">
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                height: "64px !important",
                zIndex: 4,
                backgroundColor: (theme) => theme.palette.terrain[50],
                position: "sticky",
                maxWidth: "2440px",
                top: "66px",
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  maxWidth: "2440px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={6}>
                  <SpecBackButton
                    backText="Ordering"
                    onClick={() =>
                      navigate(
                        `/spec/${store.currentEstablishment?.id}/ordering`
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Badge
                    invisible={open || cartData?.cart?.items?.length === 0}
                    badgeContent={cartData?.cart?.items?.length}
                    color="primary"
                  >
                    <IconButton
                      onClick={handleMenuOpen}
                      sx={{
                        cursor: "pointer",
                        border: "2px solid",
                        borderColor: (theme) => theme.palette.primary[800],
                        backgroundColor: (theme) =>
                          open
                            ? theme.palette.terrain[900]
                            : theme.palette.terrain[100],
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.terrain[300],
                        },
                      }}
                    >
                      {open ? (
                        <CloseOutlinedIcon
                          sx={{ color: (theme) => theme.palette.pure.white }}
                        />
                      ) : (
                        <ShoppingCartOutlinedIcon />
                      )}
                    </IconButton>
                  </Badge>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ marginTop: 6, zIndex: 0 }}>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Grid item>
                  <Typography ref={topOfPageRef} variant="h3">
                    Place New Order
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid
              item
              sx={{
                marginTop: 6,
                maxWidth: { xs: "300px", md: "1400px", overflow: "scroll" },
              }}
            >
              {vendorData &&
                vendorData.vendors?.map((v) => (
                  <VendorFilterChip
                    vendor={v}
                    key={v.id}
                    selected={vendorFilter?.id === v.id}
                    clickChip={handleVendorChipClick}
                  />
                ))}
            </Grid> */}
            <Grid
              item
              sx={{
                marginTop: 6,
                width: "100%",
              }}
            >
              <Typography variant="h4">A-Z Products</Typography>
            </Grid>
            <Box
                    sx={{
                      width: "100%",
                      marginTop: 4,
                      minWidth: "100%",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      id="productSearch"
                      onChange={handleSearchChange}
                      inputProps={{
                        sx: {
                          marginBottom: 1,
                        },
                      }}
                      sx={{
                        backgroundColor: (theme) => theme.palette.pure.white,
                        borderColor: (theme) => theme.palette.terrain[400],
                        width: "340px",
                        borderRadius: 1,
                      }}
                      label={
                        <>
                          <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                          Search for products...
                        </>
                      }
                    />
                  </Box>
            <Grid
              item
              sx={{
                marginTop: 6,
                marginBottom: "80px",
                width: "100%",
              }}
            >
              {isLoadingPaginatedSearchData ? (
                 <Grid container spacing={2} direction="row" sx={{ minWidth: "100%" }}>
                 <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  xs={12}
                >
                  <CircularProgress />
                </Box>
               </Grid>
              ) : (
                <NewOrderTable
                  productLibrary={searchContent
                    ? paginatedSearchData?.products
                    : paginatedProductData?.products}
                  setMissingDetailsData={handleSetMissingDetailsData}
                  // vendorFilter={vendorFilter}
                />
              )}
            </Grid>
            <Pagination
              sx={{ marginTop: 6 }}
              count={
                searchContent
                  ? paginatedSearchData?.totalPages
                  : paginatedProductData?.totalPages
              }
              onChange={handlePageSet}
              page={page}
            />
          </Grid>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        elevation={6}
        PaperProps={{
          style: {
            borderRadius: "8px",
            width: "281px",
            transform: " translateY(10px)",
            background: (theme) => theme.palette.terrain[50],
            border: "1px solid",
            borderColor: "#c6c6c6",
            height: `calc(98% - 120px)`,
            overflow: "hidden",
          },
        }}
      >
        {/* <MenuHeader> */}
        <Box sx={{ marginLeft: 2, marginTop: 4, marginBottom: 1 }}>
          <Typography variant="h4">Your Cart</Typography>
        </Box>
        {/* Cart Body */}
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 1,
          }}
        >
          <TextField
            size="small"
            fullWidth
            id="productSearch"
            autoComplete="off"
            inputProps={{
              sx: {
                marginBottom: 1,
              },
            }}
            sx={{
              backgroundColor: (theme) => theme.palette.pure.white,
              marginBottom: 2,
              margin: 2,
              borderRadius: 1,
            }}
            onChange={debouncedhandleUpdateCartSearchString}
            label={
              <>
                <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                Search for products...
              </>
            }
          />
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: `calc(100% - 170px)`,
            marginBottom: "64px",
            overflow: "scroll",
            backgroundColor: (theme) => theme.palette.terrain[100],
            padding: 0,
          }}
        >
          <List sx={{ width: "100%", marginTop: -4 }}>
            {cartData?.cart.items?.length === 0 ? (
              <Typography sx={{ marginTop: 6, textAlign: "center" }}>
                Your cart is empty
              </Typography>
            ) : (
              cartData?.cart?.items?.map((i) => (
                <ListItem
                  key={i?.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: 2,
                  }}
                >
                  <CartProductCard
                    cartItem={i}
                    cartSearchString={cartSearchString}
                    removeCartItem={handleRemoveCartItem}
                    locale={locale}
                    currency={currency}
                    currencySymbol={currencySymbol}
                    countUpdateCallback={handleCountUpdateCallback}
                  />
                </ListItem>
              ))
            )}
          </List>
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            height: "64px",
            width: "281px",
            backgroundColor: (theme) => theme.palette.terrain[50],
            borderTop: "1px solid",
            borderColor: (theme) => theme.palette.terrain[400],
            padding: 2,
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() =>
                navigate(`/spec/${store.currentEstablishment?.id}/cart/review`)
              }
              sx={{ margin: "auto", width: "300px", height: "40px" }}
            >
              <Typography variant="largeButton">Review Order</Typography>
            </Button>
          </Box>
        </Box>
      </Popover>
      <ProductDetailsMissingModal
        isOpen={productDetailsMissingOpen}
        toggleModalOpen={handleCloseMissingDetailsModal}
        missingDetailsData={missingDetailsData}
        vendors={vendorData?.vendors}
        unitsOfMeasurement={uomData?.unitsOfMeasurement}
      />
      <LiveEditErrorModal
        isOpen={errorModalOpen}
        toggleErrorModalOpen={toggleErrorModalOpen}
        bodyText="Are you sure you want to navigate back? You will lose any unsaved information."
        headerText="Hold on..."
        buttonText="Go Back"
      />
      <Box>
        <Snackbar
          open={alertSuccess ? true : false}
          onClose={() => setAlertSuccess(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {alertSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage ? true : false}
          onClose={() => setErrorMessage(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default NewOrder;
