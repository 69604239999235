import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useOrderCart(establishmentId, enabled) {
    const queryReturnValues = useQuery(
        ["ordercart", { establishmentId }],
        inventoryClient.getEstablishmentOrderCart,
        { refetchOnWindowFocus: false, enabled }
    );

   return queryReturnValues;
}

export default useOrderCart;