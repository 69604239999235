import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import DescriptionToolTip from "../../../../../components/DescriptionToolTip";

const AdditionalOptions = ({ allowUnspecified, toggleAllowUnspecified }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox
          checked={allowUnspecified}
          onChange={() => toggleAllowUnspecified(!allowUnspecified)}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Allow Unspecified
        </Typography>
        <DescriptionToolTip
          header="Allow Products with Unspecified Expense Groups"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Whether or not to include a line item for invoice line items
                with an unspecified values as their Expense Group or Subgroup.
                This means if a line item does not have an expense group set, it
                will get grouped under an &apos;Unspecified&apos; line item.
              </Box>
            </Box>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Include All Sum Row
        </Typography>
        <DescriptionToolTip
          header="Include All Sum Row"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Include a row at the top of the entire report showing the sum of
                all invoices in the expense group or subgroup.
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default AdditionalOptions;
