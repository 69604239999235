import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { orderReportOrderByTypes } from "../../../../clients/inventoryObjects";

const toggleButtonStyle = {
  borderTopRightRadius: "8px !important",
  borderBottomRightRadius: "8px !important",
  borderTopLeftRadius: "8px !important",
  borderBottomLeftRadius: "8px !important",
  border: "none",
  backgroundColor: (theme) => theme.palette.terrain[200],
  "&.Mui-selected, &.Mui-selected:hover": {
    color: (theme) => theme.palette.pure.white,
    backgroundColor: (theme) => theme.palette.terrain[900],
  },
};

const SortBy = ({ sortByChoice, setSortBy }) => {
  const handleSelect = (_, v) => {
    setSortBy(v);
  };

  return (
    <Box>
      <Box sx={{ marginTop: 2 }}>
        <ToggleButtonGroup
          value={sortByChoice}
          onChange={handleSelect}
          exclusive
        >
          <ToggleButton
            value={orderReportOrderByTypes.orderTotal}
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Cost
            </Typography>
          </ToggleButton>
          <ToggleButton
            value={orderReportOrderByTypes.quantityOrdered}
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Quantity Received (units)
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default SortBy;
