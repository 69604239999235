import React from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import utilFunctions from "../../../utilities/utilFunctions";

const VendorOrderReviewCard = ({
  order,
  currencySymbol,
  currencyLocale,
  currency,
  renderTopMargin,
}) => {
  const calculateVendorCost = (lineItems) => {
    let total = 0;

    lineItems.forEach((x) => {
      const itemCost =
        x.estimatedCost?.amount === null ||
        x.estimatedCost?.amount === undefined
          ? 0
          : x.estimatedCost.amount;
      const lineItemTotal = utilFunctions.bankersRound(
        itemCost * x.orderAmount,
        0
      );
      total += lineItemTotal;
    });

    return `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
      total,
      currencyLocale,
      currency
    )}`;
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: renderTopMargin ? 4 : 0,
        }}
      >
        <Grid item sx={{ marginBottom: 2 }}>
          <Typography variant="h4">{order.vendor?.name || ""}</Typography>
        </Grid>
        <Grid
          item
          sx={{
            paddingLeft: 8,
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              marginRight: 1,
            }}
          >
            Products:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.pure.black, marginRight: 1 }}
          >
            {order.lineItems?.length || 0}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              marginRight: 1,
            }}
          >
            {"|"}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              marginRight: 1,
            }}
          >
            Estimated Total Cost:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.pure.black, marginRight: 1 }}
          >
            {calculateVendorCost(order.lineItems)}
          </Typography>
        </Grid>
      </Grid>
      {order.lineItems?.map((x) => (
        <Box
          sx={{
            width: "100%",
            height: "64px",
            border: "1px solid",
            padding: 2,
            borderRadius: 2,
            borderColor: (theme) => theme.palette.terrain[300],
            backgroundColor: (theme) => theme.palette.pure.white,
            marginTop: 2,
          }}
          key={x.id}
        >
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Grid
                container
                direction="column"
                sx={{
                  display: "flex",
                }}
              >
                <Grid item>
                  <Typography variant="subtitle2">
                    {x.productName}{" "}
                    {x.unitSize === null
                      ? `(${x.unitSizeAbbreviation}`
                      : `(${x.unitSize} ${x.unitSizeAbbreviation}`}
                    {x.sku === null ? ")" : `, SKU: ${x.sku})`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {x.orderAmount} {x.purchaseUnitName}{" "}
                    {`(${x.totalUnitsOrdered} units)`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item sx={{ marginRight: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {x.estimatedCost?.amount > 0
                      ? `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
                          x.estimatedCost.amount,
                          currencyLocale,
                          currency
                        )}`
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default VendorOrderReviewCard;
